






import Vue from "vue";
import ProductDaysOfMonth from "./partials/ProductDaysOfMonth.vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  components: {
    ProductDaysOfMonth
  },
  data() {
    return {
      userManager
    };
  }
});
